import React, {useState} from "react"
import FileUploader from "./fileUploader"


export default function TaskEditor({task, setTask}) {
  const setTaskFile = (type, fileName, fullPath, url) => {
    console.log(task);
    const newTask = {...task};
    newTask.files[type] = fileName;
    newTask.urls[type] = url;
    newTask.fullPaths[type] = fullPath;
    setTask(newTask);
  }

  return (
    task ? <div className="col">
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">Kategória</span>
        <select className="form-select" value={task?.type ? task.type : ""} onChange={e => {
          const newTask = {...task};
          newTask.type = e.target.value;
          setTask(newTask);
        }}>
          <option value="Szövegszerkesztés">Szövegszerkesztés</option>
          <option value="Táblázatkezelés">Táblázatkezelés</option>
          <option value="Adatbáziskezelés">Adatbáziskezelés</option>
          <option value="Webszerkesztés">Webszerkesztés</option>
          <option value="Grafika">Grafika</option>
          <option value="" disabled hidden></option>
        </select>   
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">Név</span>
        <input type="text" className="form-control" value={task && task.name ? task.name : ""} onChange={e => {
          setTask( {...task, name: e.target.value});
        }}/>
      </div>
      <FileUploader title="Feladat" fileName={task.files.task} fullPath={task.fullPaths.task} setTaskFile={setTaskFile} type="task"/>
      <FileUploader title="Forrás" fileName={task.files.resource} fullPath={task.fullPaths.resource} setTaskFile={setTaskFile} type="resource"/>
      <FileUploader title="Pontozás" fileName={task.files.grading} fullPath={task.fullPaths.grading} setTaskFile={setTaskFile} type="grading"/>
      <FileUploader title="Megoldás" fileName={task.files.solution} fullPath={task.fullPaths.solution} setTaskFile={setTaskFile} type="solution"/>
      <FileUploader title="Útmutató" fileName={task.files.guide} fullPath={task.fullPaths.guide} setTaskFile={setTaskFile} type="guide"/>
    </div>
    : null
  )
}