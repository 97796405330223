import React, { useRef } from "react"
import slugify from "slugify";
import {PlusIcon, TrashIcon} from '@primer/octicons-react'


export default function ListEditor({title, className, size, listItems, setListItems, index, setIndex, addItem}) {
  const select = useRef(null);
  const input = useRef(null);
  return (
    <div className={className}>
      <div className="d-flex mb-2">
        <h4 className="me-auto m-0 lh-base">{title}</h4>
        <button className="btn btn-danger me-1" type="button" onClick={() => {
          if(index === null) return;
          let newListItems = [...listItems];
          newListItems.splice(index, 1);
          setListItems(newListItems);
          setIndex(null);
        }}><TrashIcon size={16} /></button>
        <button className="btn btn-success" type="button" onClick={() => {
          addItem();
        }}><PlusIcon size={16} /></button>
      </div>
      <select className="form-select" ref={select} value={index === null ? '' : index} size={size} onChange={e => {setIndex(e.target.selectedIndex);}}>
        {
          listItems ? listItems.map((item, i) => (<option key={slugify(item.title)} value={i}>{item.title}</option>)) : null
        }
        <option value="" disabled hidden></option>
      </select>
      <div className="input-group mt-3 mb-3">
        <input type="text" ref={input} className="form-control" value={index === null ? '' : listItems[index].title} onChange={e => {
          if (index == null) return;
          let newListItems = [...listItems];
          newListItems[index].title = e.target.value;
          setListItems(newListItems);
        }}/>
      </div>
    </div>
  )
}