import React from "react"
import firebase from "gatsby-plugin-firebase"
import { Link } from "gatsby"
import { PersonFill } from "react-bootstrap-icons"

const NavbarItem = ({ to, children }) => (
  <li className="nav-item">
    <Link className="nav-link" activeClassName="active" to={to}>
      {children}
    </Link>
  </li>
)

const NavBar = ({ user }) => {
  if (user && user.uuid != "OA2vbahz4DWrvOkKVmUcr3Kl6cx2") {
    return (
      <>
        <NavbarItem to="/">Versenykereső</NavbarItem>
        <NavbarItem to="/rolunk">Rólam</NavbarItem>
        <NavbarItem to="/admin">Admin</NavbarItem>
      </>
    )
  }

  return (
    <>
      <NavbarItem to="/">Versenykereső</NavbarItem>
      <NavbarItem to="/rolunk">Rólam</NavbarItem>
    </>
  )
}

const Brand = ({ to, children }) => (
  <Link className="navbar-brand" to={to}>
    {children}
  </Link>
)

const login = setUser => {
  const provider = new firebase.auth.GoogleAuthProvider()
  firebase.auth().languageCode = "hu"

  firebase
    .auth()
    .signInWithPopup(provider)
    .then(result => {
      /** @type {firebase.auth.OAuthCredential} */
      //var credential = result.credential;
      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = credential.accessToken;
      // The signed-in user info.
      //var user = result.user;
      console.log(result.user)
      typeof localStorage !== "undefined" &&
        localStorage.setItem("currentUser", JSON.stringify(result.user))
      setUser(result.user)
      typeof window !== "undefined" && window.location.reload()
      // ...
    })
    .catch(error => {
      console.log(error.message)
    })
}

const logout = setUser => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      setUser(null)
      typeof localStorage !== "undefined" &&
        localStorage.setItem("currentUser", JSON.stringify(null))
      typeof window !== "undefined" && window.location.reload()
    })
    .catch(error => {
      // An error happened.
    })
}

export default function Layout({ setUser, user, children }) {
  typeof firebase.analytics === 'function' && firebase.analytics()
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="container">
          <Brand to="/">infoverseny.hu</Brand>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">
              <NavBar user={user} />
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <PersonFill />
                  {user ? " " + user.displayName : " Fiók"}
                </a>
                <ul className="dropdown-menu">
                  {user ? (
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={e => {
                          e.preventDefault()
                          logout(setUser)
                        }}
                      >
                        Kilépés
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={e => {
                          e.preventDefault()
                          login(setUser)
                        }}
                      >
                        Belépés Google-al
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-4">{children}</div>
    </>
  )
}
