import React, { useState, useEffect } from "react"
import ListEditor from "./editor/listEditor"
import TaskEditor from "./editor/taskEditor"
import {UploadIcon} from '@primer/octicons-react'
import firebase from "gatsby-plugin-firebase"


export default function Editor({user}) {
  // set states
  const [competitions, setCompetitions] = useState([]);
  const [indexes, setIndexes] = useState({name: null, category: null, round: null, year: null, task: null});
  const [uploadState, setUploadState] = useState(null);
  
  // get filters from firestore and set competitions to it
  useEffect(() => {
    firebase && firebase.firestore().collection("filters").doc("filters").get().then(doc => {
      setCompetitions(doc.data().filters || []);
    }).catch((error) => {
      console.log("Error while querying years: ", error);
  });
  }, []);

  const queryTasks = (yearIndex) => {
    firebase && firebase.firestore().collection("years")
      .where("competition", "==", competitions[indexes.name].title)
      .where("category", "==", competitions[indexes.name].categories[indexes.category].title)
      .where("round", "==", competitions[indexes.name].categories[indexes.category].rounds[indexes.round].title)
      .where("title", "==", competitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[yearIndex].title)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          competitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[yearIndex].id = doc.id;
          setTasks(doc.data().tasks || []);
        });
      })
      .catch((error) => {
          console.log("Error while querying tasks for that year: ", error);
      });
  }

  const uploadTasks = () => {
    const filters = [];
    const years = [];
    for(const comp of competitions) {
      filters.push({title: comp.title, categories: []});
      const filterCategories = filters[filters.length - 1].categories;
      for(const cat of comp.categories) {
        filterCategories.push({title: cat.title, rounds: []});
        const filterRounds =  filterCategories[filterCategories.length - 1].rounds;
        for(const round of cat.rounds) {
          filterRounds.push({title: round.title, years: []});
          const filterYears = filterRounds[filterRounds.length - 1].years;
          for(const year of round.years) {
            filterYears.push({title: year.title});
            year.competition = comp.title;
            year.category = cat.title;
            year.round = round.title;
            years.push(year);
          }
        }
      }
    }
    for(const year of years) {
      firebase.firestore().collection("years").doc(year.id || undefined).set(year).then((id) => {
        competitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[indexes.year].id = id;
        setUploadState("success");
        console.log("Document successfully written!");
      })
      .catch((error) => {
          setUploadState("error");
          console.error("Error writing document: ", error);
      });
    }
    firebase.firestore().collection('filters').doc('filters').set({filters: filters}).then(() => {
      setUploadState("success");
      console.log("Document successfully written!");
    })
    .catch((error) => {
        setUploadState("error");
        console.error("Error writing document: ", error);
    });
  }

  const setNameIndex = (index) => { setIndexes({name: index, category: null, round: null, year: null, task: null}); }
  const setCategoryIndex = (index) => { setIndexes({...indexes, category: index, round: null, year: null, task: null});  }
  const setRoundIndex = (index) => { setIndexes({...indexes, round: index, year: null, task: null}); }
  const setYearIndex = (index) => {
    console.log(index);
    setIndexes({...indexes, year: index, task: null});
    indexes.year = index;
    let newCompetitions = [...competitions];
      let tasks = newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[index].tasks;
      // when setting year index query for tasks for that year
      if(tasks == null || tasks.length == 0) queryTasks(index);

  }
  const setTaskIndex = (index) => { setIndexes({...indexes, task: index}); }

  // functions for adding
  const addCompetition = () => { setCompetitions([...competitions, {title:"új verseny", categories: []}] );  }

  const addCategory = () => {
    if(indexes.name === null) return;
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories.push({title: "új kategória", rounds: []});
    setCompetitions(newCompetitions);
  }

  const addRound = () => {
    if(indexes.category === null) return;
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories[indexes.category].rounds.push({title: "új forduló", years: []});
    setCompetitions(newCompetitions);
  }

  const addYear = () => {
    if(indexes.round === null) return;
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years.push({title: "új év", tasks: []});
    //indexes.year = newCompetitions[indexes.name].categories[indexes.category].rounds.length-1;
    setCompetitions(newCompetitions);
  }

  const addTask = () => {
    if(indexes.year === null) return;
    let newCompetitions = [...competitions];
    const year = newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[indexes.year];
    year.tasks = year.tasks || [];
    year.tasks.push({title: "új feladat", files: {}, urls: {}, fullPaths: {}});
    setCompetitions(newCompetitions);
  }

  // function for setting
  const setCategories = (categories) => {
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories = categories;
    setCompetitions(newCompetitions);
  }
  
  const setRounds = (rounds) => {
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories[indexes.category].rounds = rounds;
    setCompetitions(newCompetitions);
  }

  const setYears = (years) => {
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years = years;
    setCompetitions(newCompetitions);
  }

  const setTasks = (tasks) => {
    let newCompetitions = [...competitions];
    console.log(indexes);
    newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[indexes.year].tasks = tasks;
    setCompetitions(newCompetitions);
  }

  const setTask = (task) => {
    let newCompetitions = [...competitions];
    newCompetitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[indexes.year].tasks[indexes.task] = task;
    setCompetitions(newCompetitions);
  }

  const listSize = 8;

  const UploadAlert = ({status, setStatus}) => {
    switch(status) {
      case 'success':
        return (<div className="alert alert-success d-flex" role="alert">
          <div className="me-auto">Sikeres feltöltés!</div>
          <button type="button" className="btn-close" onClick={()=>{setStatus(null)}}></button>
        </div>);
      case 'error':
        return (<div className="alert alert-danger d-flex" role="alert">
          <div className="me-auto">Sikertelen feltöltés!</div>
          <button type="button" className="btn-close" onClick={()=>{setStatus(null)}}></button>
        </div>);
      default:
        return null;
    }
  }

  if(user && user.uuid != "OA2vbahz4DWrvOkKVmUcr3Kl6cx2") {
    return (
      <>
        <div className="modal fade" id="saveModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Biztosan mented az adatbázist?</h5>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Mégse</button>
                <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={uploadTasks}>Mentés</button>
              </div>
            </div>
          </div>
        </div>
        <UploadAlert status={uploadState} setStatus={setUploadState} />
        <div className="d-flex mb-3"><button className="btn btn-success ms-auto" data-bs-toggle="modal" data-bs-target="#saveModal"><UploadIcon size={16} />{' '}Mentés</button></div>
        <div className="row">
          <div className="col-xl-8">
            <div className="row">
            <ListEditor title="Verseny" size={listSize} className="col-md-6" listItems={competitions} setListItems={setCompetitions} setIndex={setNameIndex} addItem={addCompetition} index={indexes.name}/>
            <ListEditor title="Kategória" size={listSize} className="col-md-6" listItems={indexes.name === null ? [] : competitions[indexes.name].categories} setListItems={setCategories} setIndex={setCategoryIndex} addItem={addCategory} index={indexes.category}/>
            <ListEditor title="Forduló" size={listSize} className="col-md-6" listItems={indexes.category === null ? [] : competitions[indexes.name].categories[indexes.category].rounds} setListItems={setRounds} setIndex={setRoundIndex} addItem={addRound} index={indexes.round}/>
            <ListEditor title="Év" size={listSize} className="col-md-6" listItems={indexes.round === null ? [] : competitions[indexes.name].categories[indexes.category].rounds[indexes.round].years} setListItems={setYears} setIndex={setYearIndex} addItem={addYear} index={indexes.year}/>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="row">
              <ListEditor title="Feladat" size={4} className="col" listItems={indexes.year === null ? [] : competitions[indexes.name].categories[indexes.category].rounds[indexes.round].years[indexes.year].tasks} setListItems={setTasks} setIndex={setTaskIndex} addItem={addTask} index={indexes.task}/>
            </div>
            <div className="row">
              <TaskEditor task={indexes.task === null ? null : competitions[indexes.name]?.categories[indexes.category]?.rounds[indexes.round]?.years[indexes.year]?.tasks[indexes.task]} 
                setTask={setTask}/>
            </div>
          </div>
        </div>
      </>
    );
  }
  else {
    return (
      <div className="alert alert-danger d-flex" role="alert">
        <div className="me-auto">Nincs jogosultságod az oldal megtekintéséhez!</div>
      </div>
    );
  }
}

