import React, {useState, useRef} from "react"
import firebase from "gatsby-plugin-firebase"
import {UploadIcon} from '@primer/octicons-react'

const UploadAlert = ({status, setStatus}) => {
  switch(status) {
    case 'success':
      return (<div className="alert alert-success d-flex" role="alert">
        <div className="me-auto">Sikeres feltöltés!</div>
        <button type="button" className="btn-close" onClick={()=>{setStatus(null)}}></button>
      </div>);
    case 'error':
      return (<div className="alert alert-danger d-flex" role="alert">
        <div className="me-auto">Sikertelen feltöltés!</div>
        <button type="button" className="btn-close" onClick={()=>{setStatus(null)}}></button>
      </div>);
    default:
      return null;
  }
}

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export default function FileUploader({title, fileName, fullPath, setTaskFile, type}) {
  const [uploadState, setUploadState] = useState(null);
  const hiddenFileInput = useRef(null);

  const uploadFile = (file) => {
    if(fullPath) {
      // Create a reference to the file to delete
      const currentFile = firebase.storage().ref().child(fullPath);

      // Delete the file
      currentFile.delete().then(() => {
        console.log('Deleted current file');
      }).catch((error) => {
        console.log('Error deleting current file:', error);
      });
    }
    const uploadTask = firebase.storage().ref().child('/'+ uuidv4() + '/' + file.name).put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {console.log(error); setUploadState('error')}, 
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          setUploadState('success');
          const ref = uploadTask.snapshot.ref;
          setTaskFile(type, ref.name, ref.fullPath, downloadURL);
        })
      }
    );
  }

  return (
    <>
    <UploadAlert status={uploadState} setStatus={setUploadState}/>
    <div className="input-group mb-3">
      <label className="input-group-text">{title}</label>
      <input type="text" className="form-control" value={fileName ? fileName : ""} readOnly/>
      <input type="file" ref={hiddenFileInput} className="d-none" onChange={e => {uploadFile(e.target.files[0])} }/>
      <button className="btn btn-success" type="button" onClick={() => {hiddenFileInput.current.click()}}><UploadIcon  size={16}/></button>
    </div>
    </>
  )
}